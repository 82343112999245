import { marked } from "marked";

export default {
	install: (app, options) => {
		let rootUrl =  options.config[process.env.NODE_ENV].adminUrl
	
		let markdownToHtml = (mdText) => {
		
			mdText = mdText.replaceAll('(/uploads/', '('+rootUrl +'/uploads/')
			return marked(mdText);
			
		  };


		//available as this.$apiGetData
	  app.config.globalProperties.$mdToHtml = markdownToHtml;
		  
	}
  }