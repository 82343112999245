<template>
<iframe class="msp-map mx-auto" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11601.038646713972!2d1.4203878!3d43.3715944!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x51fe22ddd5e75de!2sMSP%20de%20Miremont!5e0!3m2!1sfr!2sfr!4v1647420459237!5m2!1sfr!2sfr" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  <!--GoogleMap
  api-key="AIzaSyCMDlmWqmSsgA6a2AW4iJe0Azj-DncBSz0"
  style="width: 100%; height: 500px"
  :center="center"
  :zoom="13"
  >
    <Marker :options="{ position: center }" />
  </GoogleMap-->
</template>

<script>
//import { GoogleMap, Marker } from 'vue3-google-map'

export default {
	name : 'Map',
	components : {},
	computed:{
		/*center(){
			return { lat: 43.3727899, lng: 1.419385 }
		}*/
	}
}
</script>

<style lang="scss">

.msp-map{
  height: 300px;
} 

@media screen and (min-width: 1200px) {
	.msp-map {
			height: 400px;
		}
	
}

</style>