
<template>
  <img class="img-fluid" 
  :src="$root.baseUrl + img.url" 
  :srcset="srcset" 
  :sizes="sizes"
  :title="img.caption" 
  :alt="img.alternativeText" 
  :width="img.width" 
  :height="img.height" />
</template>

<script>
/**
 * Needs an img object
 * 
 */

export default {
	name : "Image",
	props : ['img', 'sizes'],
	computed : {
		srcset(){
			return this.$root.baseUrl+ this.img.formats.large.url + ' 1000w ,'+ this.$root.baseUrl+ this.img.formats.medium.url + ' 750w, ' + this.$root.baseUrl+ this.img.formats.small.url + ' 500w, '
		}
	},
	mounted(){
		//console.log('image component mounted with img ', this.img)

	}
}
</script>

<style>

</style>