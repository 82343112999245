<template>
<Page :name="'msp'"  @loaded="onLoaded">
    	<template v-slot:content>
			<div class="container">
				<div class="row">
					<div class="col-12">
						<h1>{{data.Titre}}</h1>
						<section id="msp-stats" class="row mb-4">
							<div class="col-12 col-md-4 stat px-2" v-for="stat, index in data.statistiques" :key="'stat-'+index">
								<div class="stat-wrapper d-flex flex-column justify-content-center align-items-center  bg-light p-4">
									<span class="stat-chiffre">{{stat.chiffre}}</span>
									<span class="stat-text">{{stat.text}}</span>
								</div>
							</div>
						</section>
						<section id="msp-slider" class="mb-4">
							<div class="slider">
							<div class="swiper-wrapper">
								<div class="swiper-slide" v-for="(slide, index) in data.slider" :key="'slide-' + index">
									<Image :img="slide" />
								</div>
							</div>
						</div>
						</section>
						<section id="msp-description" v-html="$mdToHtml(data.description)" class="html-content py-5">

						</section>
					</div>
				</div>
			</div>
		</template>
  </Page>
</template>

<script>
import Swiper, { Autoplay, Navigation } from "swiper";
Swiper.use([Autoplay, Navigation]);
export default {
name : 'MSP',
	data (){
		return {
			data : null,
			sliderSettings:{
				autoplay: true,
				centerSlides: true,
				loop: true,
			},
			slider : null
		}
	},
	

	mounted(){
		//console.log('news mounted',  this.data)
	},
	methods: {
    onLoaded(data){
			this.data = data;
			this.initSlider();
		},
		initSlider(){
			this.$nextTick(() => {
				setTimeout(() => {
				this.slider = new Swiper("#msp-slider .slider", this.sliderSettings);
				}, 100);
			});
		}
			
	}
}
</script>

<style lang="scss" scoped>
.page-msp{
	#msp-stats{
			color: var(--secondary);
		.stat-chiffre{
			font-family: var(--font-M);
			font-weight: bold;
			font-size: 2rem;
		
		}

		.stat-text{
			font-weight: 500;
		}

	}
	#msp-slider{
		//height: 300px;
		aspect-ratio: 2.5;
		position: relative;
		overflow: hidden;
		.slider{
			width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
			//height: 100%;
		}
	}
}

</style>