<template>
  <Page :name="'news-detail'" @loaded="onLoaded">
		<template v-slot:content>
			<div class="container">
				<div class="row">
					<div class="col-12">
						<nav v-if="data" aria-label="breadcrumb">
							<ol class="breadcrumb mt-4">
								<li class="breadcrumb-item"><router-link :to="{name : 'news'}">Actualités</router-link></li>
								<li class="breadcrumb-item active" aria-current="page">{{data.title}}</li>
							</ol>
						</nav>
					</div>
				</div>
				<div class="row">
					<div class="col-12" v-if="data">
						<h1>{{data.title}}</h1>
						<div v-html="$mdToHtml(data.text)" class="news-content"></div>
					</div>
				</div>
			</div>
			
		</template>
	</Page>
</template>

<script>
export default {
	name : "News détail",
	data(){
		return {
			data :null
		}
	},
	methods: {
		onLoaded(data) {
			this.$apiGetData("newslist", this.$route.params.id)
			.then((data) => {
				document.title = data.title 
				this.data = data;
			})
			.catch((err) => {
				console.warn(err);
			});
		
		},
	}

}
</script>

<style lang="scss">

.page-news-detail{
	.news-content{
		img{
			max-width: 100%;
			margin: auto;
		}
	}
}
</style>