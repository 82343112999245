<template>
	<Page :name="'home'" @loaded="onLoaded">
		<template v-slot:content>
			<div class="row">
				<div class="col-12 p-0">
					<section id="home-slider" class="position-relative">
						<div class="slider">
							<div class="swiper-wrapper">
								<div class="swiper-slide" v-for="(slide, index) in data.top_slider" :key="'slide-' + index">
									<Image :img="slide" />
								</div>
							</div>
						</div>
						<router-link :to="{ name: 'rdv' }" class="btn btn-primary position-absolute text-uppercase bottom-btn center-btn">Prendre rendez-vous</router-link>
					</section>
				</div>
			</div>
			<div class="container">
				<div class="row">
					<div class="col-12 p-0">
						<section id="home-equipe" class="position-relative py-5 border-blue">
							<h1 class="d-none">{{ data.Titre }}</h1>
							<ul class="ulli-reset d-flex flex-row align-items-center justify-content-center flex-wrap py-4">
								<li
									class="col-12 col-sm-6 col-md-4 col-lg-2 d-flex flex-column justify-content-start align-items-center mb-4 specialite-item"
									v-for="(specialite, index) in data.specialites"
									:key="'specialite-' + index"
								>
									<h2 class="text-center text-uppercase fs-6 text-secondary">{{ specialite.Titre }}</h2>

									<ButtonPicto :icon-name="specialite.Nom_picto" variant="secondary" :linkto="{ name: 'pro', params: { id: specialite.id } }"></ButtonPicto>
								</li>
							</ul>
							<router-link :to="{ name: 'urgence' }" class="btn btn-primary position-relative text-uppercase center-btn">Liens et numéros d'urgence</router-link>
						</section>
					</div>
				</div>
			</div>
			<div class="row">
				<section id="home-news" v-if="news">
					<div class="container h-100">
						<div class="row h-100">
							<div class="col-12 h-100">
								<div class="slider py-4 py-md-5 position-relative">
									<div class="slider-wrapper">
										<div class="swiper-wrapper">
											<div class="swiper-slide news-slide px-2" v-for="(news, index) in lastNews" :key="'slide-' + index">
												<NewsItem class="h-100 p-2" :content="news" short="true" :linkto="{ name: 'news-detail', params: { id: news.id } }"></NewsItem>
											</div>
										</div>
									</div>

									<div class="swiper-button-prev swiper-nav position-absolute" v-show="showPrev"><span class="material-icons"> arrow_back_ios </span></div>
									<div class="swiper-button-next swiper-nav position-absolute" v-show="showNext"><span class="material-icons"> arrow_forward_ios </span></div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<div class="row">
				<div class="col-12 p-0">
					<section id="home-map" class="d-flex">
						<Map></Map>
					</section>
				</div>
			</div>
		</template>
	</Page>
</template>

<script>
// use https://swiperjs.com/get-started
// https://swiperjs.com/migration-guide#parameters-updates
import Swiper, { Autoplay, Navigation } from "swiper";
Swiper.use([Autoplay, Navigation]);

export default {
	name: "Home",
	components: {},
	data() {
		return {
			data: null,
			news: [],
			topSliderSettings: {
				autoplay: true,

				centerSlides: true,
				loop: true,
			},
			newsSliderSettings: {
				autoHeight: true,
				observer: true,
				observeParents: true,
				parallax: true,
				navigation: false,
				centerSlides: true,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				//loop: true,
				arrows: true,
				slidesPerView: 2,
				slidesPerGroup: 2,
				breakpoints: {
					992: {
						slidesPerView: 3,
						slidesPerGroup: 3,
					},
				},
				on: {
					slideChange: (swiper) => {
						if (window.innerWidth >= 992) {
							if (swiper.activeIndex == 0) {
								this.showPrev = false;
								this.showNext = true;
							} else if (swiper.activeIndex == swiper.params.slidesPerView) {
								this.showPrev = true;
								this.showNext = false;
							}
						}
					},
				},
			},
			showPrev: false,
			showNext: true,
			topSlider: null,
			newsSlider: null,
			newsToDisplayNum: 6,
		};
	},

	mounted() {
		//console.log("home mounted", this.data, this.$root.baseUrl, window.innerWidth < 992);
	},
	computed: {
		lastNews() {
			//return 3 first news
			return this.news.slice(0, this.newsToDisplayNum);
		},
	},
	methods: {
		onLoaded(data) {
			this.data = data;
			this.$apiGetData("newslist")
				.then((news) => {
					this.news = this.$root.sortDate(news);
					this.initNewsSlider();
				})
				.catch((err) => {
					console.err(err);
				});

			this.initTopSlider();
		},
		initTopSlider() {
			this.$nextTick(() => {
				let to = setTimeout(() => {
					this.topSlider = new Swiper("#home-slider .slider", this.topSliderSettings);
					clearTimeout(to);
				}, 100);
			});
		},
		initNewsSlider() {
			this.$nextTick(() => {
				let to = setTimeout(() => {
					this.newsSlider = new Swiper("#home-news .slider-wrapper", this.newsSliderSettings);
					clearTimeout(to);
				}, 100);
			});
		},
	},

};
</script>

<style lang="scss" scoped>
.page-home {
	#home-slider {
		aspect-ratio: 8 / 2;
		width: 100%;
		overflow: hidden;

		a.btn {
			z-index: 2;
			left: 50%;
			bottom: 5%;
			transform: translateX(-50%);
		}

		.swiper-slide {
			overflow: hidden;

			img {
				min-width: 100%;
				min-height: 100%;
				width: auto;
				max-width: none;
				height: auto;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	#home-news {
		overflow: hidden;

		.slider-wrapper {
			height: 100%;
			overflow: hidden;
		}

		.swiper-nav {
			display: none;
			cursor: pointer;
			top: 50%;
			transform: translateY(-50%);
			&.swiper-button-prev {
				left: -50px;
			}
			&.swiper-button-next {
				right: -50px;
			}
		}
	}

	#home-slider,
	#home-news {
		.slider {
			width: 100%;
			height: 100%;
		}
	}
}

@media screen and (min-width: 768px) {
	.page-home {
		#home-slider {
			//height: 300px;
		}
	}
}

@media screen and (min-width: 992px) {
	.page-home {
		#home-news {
			.swiper-nav {
				display: block;
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	.page-home {
		#home-slider {
			//height: 400px;
			//aspect-ratio: 16 / 9;
		}
	}
}

@media screen and (min-width: 1400px) {
}
</style>
