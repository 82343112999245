<template>
	<Page :name="'rdv'" @loaded="onLoaded">
		<template v-slot:content>
			<div class="container">
				<div class="row">
					<div class="col-12">
						<h1>{{ data.Titre }}</h1>
						<section class="my-5 py-5">
							<ul class="ulli-reset d-flex flex-row align-items-center justify-content-center flex-wrap">
								<li v-for="(specialite, index) in data.specialites" :key="'spec-' + index" class="col-6 col-md-4 col-lg-2 text-center">
									<p class="nav-title text-center text-uppercase fs-6 text-secondary">{{ specialite.Titre }}</p>
									<ButtonPicto v-if="specialite" :icon-name="specialite.Nom_picto" variant="secondary" class="mx-auto" :href="'#specialite-' + index"></ButtonPicto>
								</li>
							</ul>
						</section>
						<section v-for="(specialite, index) in data.specialites" :key="'section-' + index" :id="'specialite-' + index" class="mb-5 col-12 col-md-8 mx-auto">
							<h2 class="text-left text-uppercase fs-5 text-secondary mb-0">{{ specialite.Titre }}</h2>
							<ul class="ulli-reset bg-light p-4">
								<li v-for="(praticien, index) in getPracticiensForSpecialite(specialite.id)" :key="'prat-' + index">
									<div class="row flex-row">
										<div class="left col-6 text-end">
											<span
												><strong>{{ praticien.firstname }} {{ praticien.lastname }} : </strong></span
											>
										</div>
										<div class="right col-6 text-start">
											<p v-if="praticien.phone" class="mb-0">
												Tel : <a class="primary" :href="'tel:' + praticien.phone">{{ praticien.phone }}</a>
											</p>
											<p><a v-if="praticien.rdv_link" target="_blank" :href="praticien.rdv_link">Doctolib</a></p>
										</div>
									</div>
								</li>
							</ul>
						</section>
					</div>
				</div>
			</div>
		</template>
	</Page>
</template>

<script>
export default {
	name: "Rdv",
	data() {
		return {
			data: null,
		};
	},

	mounted() {
		console.log('rdv mounted',  this.data)
	},
	methods: {
		onLoaded(data) {
			this.data = data;
			console.log('rdv loaded',  this.data)
		},
		getPracticiensForSpecialite(id) {
			return this.data.praticiens.filter((prat) => prat.specialite == id);
		},
	},
};
</script>

<style lang="scss">
.page-rdv {
	h2 {
		border-bottom: 1px solid var(--secondary);
	}

	.nav-title {
		font-weight: 500;
	}
}
</style>
