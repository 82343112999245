<template>
  <div class="page-wrapper">
	  		<Spinner v-if="!isLoaded" />
		  <div v-else-if="isLoaded && error" class="alert alert-danger" role="alert">{{error}}</div>
	   <div v-else>	   
		   <slot name="content"></slot>
	   </div>
  	
  </div>
</template>

<script>

export default {
	name : "Page",
	props : ['name'],
	data(){
		return {
			isLoaded : false,
			data : null,
			error : null
		}
	},
	beforeMount(){
		this.$apiGetData(this.name, this.$route.params.id)
		.then(data=>{
				//console.log(' data for page  ', data)
				this.data = data;
				this.$emit('loaded', data)
				document.title = this.data.Titre 
				this.isLoaded = true;
		})
		.catch(err=>{
			console.warn('in page, error', err)
			this.error = err
			this.isLoaded = true;
		})
	},
	mounted(){
		//console.log('mounted page with data ', this.data)
	},
	computed:{
		
	
	}

	
}
</script>

<style lang="scss" scoped>
.page-wrapper{
	scroll-behavior: smooth;
}

</style>