<template>
	<Page :name="'infos'" @loaded="onLoaded">
		<template v-slot:content>
			<div class="container">
				<div class="row">
					<div class="col-12">
						<h1 class="">{{ data.Titre }}</h1>
						<section id="infos-map" class="map mb-4">
							<Map></Map>
						</section>
						<section id="infos-adresse" class="mb-4 col-12 col-md-8 mx-auto bg-light p-4 d-flex flex-row">
							<div class="col-12 col-md-4">
								<h2 class="bordered-bottom">Adresse :</h2>
							</div>
							<div class="col-12 col-md-8">
								<address class="html-content" v-html="$mdToHtml(coords.address)"></address>
							</div>
						</section>
						<section id="infos-horaires" class="mb-4 col-12 col-md-8 mx-auto bg-light p-4 d-flex flex-row">
							<div class="col-12 col-md-4">
								<h2 class="bordered-bottom">Accès :</h2>
							</div>
							<div class="col-12 col-md-8">
								<div v-html="$mdToHtml(data.access)"></div>
							</div>
						</section>
						<section id="infos-equipe" class="mb-4 col-12 col-md-8 mx-auto bg-light p-4 d-flex flex-row">
							<div class="col-12 col-md-4">
								<h2 class="bordered-bottom">L'équipe :</h2>
							</div>
							<div class="col-12 col-md-8">
								<ul class="ulli-reset">
									<li v-for="(praticien, index) in praticiens" :key="'prat-' + index">
										{{ praticien.firstname }}&nbsp;{{ praticien.lastname }}&nbsp;:&nbsp; <a :href="'tel:' + praticien.phone">{{ praticien.phone }}</a>
									</li>
								</ul>
							</div>
						</section>

						<router-link :to="{ name: 'urgence' }" class="btn btn-primary position-relative text-uppercase center-btn mb-4">Liens et numéros d'urgence</router-link>
					</div>
				</div>
			</div>
		</template>
	</Page>
</template>

<script>
export default {
	name: "Infos",
	data() {
		return {
			data: null,
			praticiens: null,
		};
	},
	computed: {
		coords() {
			return this.$store.getters.getCoords;
		},
	},
	beforeMount() {
		this.$apiGetData("praticiens")
			.then((prat) => {
				this.praticiens = prat;
			})
			.catch((err) => {
				console.warn(err);
			});
	},
	mounted() {
		//console.log("infos mounted", this.data);
	},
	methods: {
		onLoaded(data) {
			this.data = data;
		},
	},
};
</script>

<style></style>
