<template>
<div class="spinner-container container position-relative mx-auto py-5 d-flex flex-column justify-content-center">
	<div class="row">
		<div class="col-12 text-center">
			<div class="spinner spinner-border text-secondary" role="status">
  				<span class="visually-hidden">Loading...</span>
			</div>
		</div>
	</div>
</div>
  
</template>

<script>
export default {
	name : "Spinner"
}
</script>

<style lang="scss">
.spinner-container{

	height: 100%;
	
}
</style>