
import { createApp } from 'vue';
import {router} from './routing/routes.js'
import 'es6-promise/auto'

//DEFAULT TEMPLATE
import App from './App.vue'

//STORE
import store from './store/store.js';

//COMPONENTS

import Page from './components/page/page.vue';
import Spinner from './components/spinner/spinner.vue'
import Image from './components/image/image.vue';
import ButtonPicto from './components/button-picto/button-picto.vue'
import NewsItem from './components/news-item/news-item.vue';
import Map from './components/map/map.vue'

//PLUGINS
import backApi from './plugins/backapi.js';
import markdown from './plugins/markdown.js';

//BOOTSTRAP JS and CSS
import '../assets/styles/custom.scss'
import bootstrap from 'bootstrap' 
//import "../../node_modules/bootstrap/scss/bootstrap"
import "../../node_modules/swiper/swiper.min.css";

import '../../node_modules/@fortawesome/fontawesome-free/css/all.min.css'
import '../../node_modules/@fortawesome/fontawesome-free/js/all.min.js'
import CONFIG from "../config.json";

const app =  createApp(App)
.use(router)
.use(store)
.use(backApi)
.use(markdown, {config: CONFIG})
//global components
.component('Spinner', Spinner)
.component('Page', Page)
.component('Image', Image)
.component('ButtonPicto', ButtonPicto)
.component('NewsItem', NewsItem)
.component('Map', Map)
.mount('#msp');

