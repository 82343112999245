<template>
	<Page :name="'pro'" @loaded="onLoaded">
		<template v-slot:content>
			<div class="container">
				<div class="row">
					<div class="col-12">
						<h1><i :class="'fas fa-' + data.Nom_picto"></i>{{ data.Titre }}</h1>
						<div class="html-content mb-4" v-html="$mdToHtml(data.text)"></div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 text-center">
					<router-link :to="{ name: 'rdv' }" class="btn btn-primary text-uppercase mx-auto my-5">Prendre rendez-vous</router-link>
				</div>
			</div>
		</template>
	</Page>
</template>

<script>
export default {
	name: "Pro",
	data() {
		return {
			data: null,
			praticiens: null,
		};
	},
	computed() {},

	mounted() {
		//console.log("pro mounted", this.data);
	},
	methods: {
		onLoaded(data) {
			this.$apiGetData("praticiens")
				.then((prat) => {
					this.praticiens = prat.filter((p) => p.specialite.id == this.data.id);
				})
				.catch((err) => {
					console.warn(err);
				});
			this.data = data;
		},
	},
};
</script>

<style lang="scss">
.page-pro {
	h1 svg {
		border-radius: 100%;
		border: 1px solid #e71f73;
		//font-size: 80%;
		padding: 0.4rem;
		margin-right: 0.5rem;
	}
}
</style>
