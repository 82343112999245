import { createStore } from "vuex" 

const store = createStore({
	state: {
	  maintenanceMode : false,
	  parameters : {},
	  coords : {}
	},
	mutations: {
		setMaintenanceMode(state, value){
			state.maintenanceMode = value;
		},
		setParameters(state, value){
			state.parameters = value;
		},
		setCoords(state, value){
			state.coords = value;
		},
	},
	getters : {
		getMaintenanceMode : state=>{
			return state.maintenanceMode
		},
		getParameters : state=>{
			return state.parameters
		},
		getCoords : state=>{
			return state.coords
		},
	}
  })

  export default store;