<template>
<Page :name="'news'" @loaded="onLoaded">
    	<template v-slot:content>
			<div class="container">
				<div class="row">
					<div class="col-12">
						<h1>{{data.Titre}}</h1>
						<section id="news-list"  v-if="news" class="row">
							
							<ul class="ulli-reset row justify-content-center mb-0">
								<li class="col-12 col-md-6 col-lg-4"  v-for="newsItem, idx in newsDisplayed" :key="'news-'+idx">
									<NewsItem :content="newsItem" class="mb-4 bg-light p-4" :short="true" :linkto="{name : 'news-detail', params : {id : newsItem.id}}"></NewsItem>
								</li>
							</ul>
						</section>
						<button v-if="currentViewNum <= news.length - 1" class="btn btn-secondary position-relative text-uppercase center-btn my-4 my-lg-5" @click="currentViewNum +=3">Voir les actualités suivantes</button>
					</div>
				</div>
			</div>
		</template>
  </Page>
</template>

<script>

export default {
name : 'News',
	data (){
		return {
			data : null,
			news : [],
			currentViewNum : 3,
		}
	},

	mounted(){
		// console.log('news mounted',  this.data)
	},
	computed : {
		newsDisplayed(){
			return this.news.filter((n, index)=>{return index < this.currentViewNum})
		}
	},
	
	methods: {
		onLoaded(data){
			this.data = data;
			this.$apiGetData("newslist")
				.then((news) => {
					this.news = this.$root.sortDate(news)
					//this.initGrid()
				})
				.catch((err) => {
					// console.error(err);
				});
	
		},
		initGrid(){
			this.$nextTick(()=>{
				setTimeout(()=>{
					this.grid = new Masonry( document.getElementById('news-list'), {
					// options
					itemSelector: '.grid-item',
					itemSize : 'grid-sizer',
					percentPosition: true
					});
									}, 100)
										
								})
		
		}
	}
}
</script>

<style  lang="scss">
.page-news{

	.news-item{
		img{
					max-width: 100%;
				}
			.news-html-opacity{	
				background: rgb(248,249,250)!important;
				background: linear-gradient(0deg, rgba(248,249,250,1) 0%, rgba(248,249,250,0) 50%)!important;
				
			}
	}
	 
}

</style>