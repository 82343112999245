import config from '../../config.json';
//console.log('NODE ENV is ', process.env.NODE_ENV)
const baseUrl =  config[process.env.NODE_ENV].adminUrl;
let backRoutes = {
	'menu' : baseUrl + '/menu',
	'parametres' : baseUrl + '/parametres',
	'home' : baseUrl + '/page-accueil',
	'equipe' : baseUrl + '/page-equipe',
	'msp' : baseUrl + '/page-msp',
	'infos' : baseUrl + '/page-infos-pratiques',
	'news' : baseUrl + '/page-actualites',
	'rdv' : baseUrl + '/page-rdv',
	'specialites' : baseUrl + '/specialites',
	'newslist' : baseUrl + '/actualites',
	'pro': baseUrl + '/specialites',
	'praticiens': baseUrl + '/praticiens',
	'coords' : baseUrl + '/coordonnees',
	'urgence' : baseUrl + '/page-urgence',
}

export default backRoutes