<template>
	<button type="button" :class="'btn button-picto position-relative d-flex flex-row justify-content-center align-items-center btn-'+variant">
		<span>{{iconName}}</span>
		<router-link v-if="linkto" :to="linkto" class="position-absolute">{{iconName}}</router-link>
		<a class="link-reset position-absolute" v-if="href" :href="href" >{{iconName}}</a>
		<!--i class="material-icons" >
			{{iconName}}
		</i-->
		<i :class="'fas fa-'+iconName"></i>
	</button>
</template>

<script>

export default {
	name : 'ButtonPicto',
	props : ['iconName', 'variant', 'linkto', 'href'],
	mounted(){
		//console.log('button picto with icon ', this.iconName, this.href)
	}
}	
</script>

<style lang="scss" scoped>


.button-picto{
	padding: 0.75rem;
	border-radius: 100%;
	width: 48px;
	height: 48px;

	span{
		opacity: 0;
		position: absolute;
		z-index: 0;
	}
	
	a{
		color: transparent;
		width: 100%;
		height: 100%;
		border-radius: 100%;
	}
	svg{
		font-size: 1.5rem;
		color: #FFF;
	}

	
}
</style>