<template>
	<Page :name="'urgence'" @loaded="onLoaded">
		<template v-slot:content>
			<div class="container">
				<div class="row">
					<div class="col-12">
						<h1 class="">{{ data.Titre }}</h1>

						<section id="urgence-content" class="mb-4 col-12">
							<div class="col-12 col-md-8 html-content" v-html="$mdToHtml(data.content)"></div>
						</section>
					</div>
				</div>
			</div>
		</template>
	</Page>
</template>

<script>
export default {
	name: "Urgence",
	data() {
		return {
			data: null,
		};
	},
	methods: {
		onLoaded(data) {
			this.data = data;
		},
	},
};
</script>

<style></style>
