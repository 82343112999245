<template>
  <header class="container px-0">
		  <div class="row">
			  <div class="col-12">

			
			  <nav class="navbar navbar-expand-lg py-2 p-md-0">
				  <div class="col-6 col-md-3" >
					    <router-link to="/"  class="navbar-brand">
							<Image v-if="menu" :sizes="'300px'" :img="menu.logo" class="logo" />
						</router-link>
				  </div>
				
				  
				  	<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#msp-menu-collapse" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
						<span class="material-icons">menu</span>
					</button>
					<div  class="collapse navbar-collapse justify-content-end" id="msp-menu-collapse">
						 <ul class="navbar-nav" v-if="menu">
							 <li class="nav-item mx-4" :class="{dropdown : item.submenu.length}" v-for="item, index in menu.menu_items" :key="'menu-'+index">
								
								<a href="#"  v-if="item.submenu.length" class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{item.name}}</a>
								<router-link v-if="!item.submenu.length" :to="{name :  item.link}" active-class="active" class="nav-link" >{{item.name}}</router-link>

								 <ul v-if="item.submenu.length" class="ulli-reset dropdown-menu">
									 <li v-for="sub, idx in item.submenu" :key="'sub-'+idx">
										 	<router-link :to="{name : sub.link}" active-class="active" class="dropdown-item">{{sub.name}}</router-link>
									</li>
								 </ul>
							 </li>
						 </ul>
					</div>
			  </nav>
			    </div>
		  </div>
  </header>
</template>

<script>

export default {
	name : "Header",
	data(){
		return {
			menu : null,
			logo : null,
			menuDropdowns : null,
			menuCollapse : null,
		}
	},
	computed:{
		
	},
	beforeMount(){
		this.$apiGetData('menu')
		.then(data=>{
				//console.log(' data for menu  ', data)
				this.menu = data;
			
				this.isLoaded = true;
					this.initMenu();
				
		})
		.catch(err=>{
			console.warn('in page, error', err)
			this.error = err
			this.isLoaded = true;
		})
	},
	beforeDestroy(){
		this.removeEvents();
	},
	methods:{
		initMenu(){
			
			let to = setTimeout(()=>{
				this.menuDropdowns = document.querySelectorAll('.dropdown')
				this.menuCollapse =document.querySelector('#msp-menu-collapse')
				this.menuDropdowns.forEach(dt=>{
					let link = dt.querySelector('.dropdown-toggle'),
						submenu = dt.querySelector('.dropdown-menu')
					link.addEventListener('mouseenter', ()=>this.showSubmenu(link, submenu))
					submenu.addEventListener('mouseleave', ()=>this.hideSubmenu(link, submenu))
				})	
				if(window.innerWidth < 992){
					let links = document.querySelectorAll('#msp-menu-collapse a:not(.dropdown-toggle')
					links.forEach(link=>{
						link.addEventListener('click', ()=>this.hideMobileMenu())
					})
				}

				clearTimeout(to)
			}, 200)
			
		},
		showSubmenu(link, submenu){
			link.classList.add('show')
			submenu.classList.add('show')
		},
		hideSubmenu(link, submenu){
			link.classList.remove('show')
			submenu.classList.remove('show')
		},
		hideMobileMenu(){
			this.menuCollapse.classList.remove('show')
		},
		removeEvents(){
			this.menuDropdowns.forEach(dt=>{
					let link = dt.querySelector('.dropdown-toggle'),
						submenu = dt.querySelector('.dropdown-menu')
					link.removeEventListener('mouseenter', ()=>this.showSubmenu(link, submenu))
					submenu.removeEventListener('mouseleave', ()=>this.hideSubmenu(link, submenu))
				})	
			}
	}
}
</script>

<style lang="scss" scoped>

.logo{
	
}
</style>