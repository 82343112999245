<template>
	<div class="container d-flex flex-column justify-content-center h-100">
		<div class="row h-100">
			<div class="col-12 text-center py-5 h-100 d-flex flex-column justify-content-center align-items-center">
				<Image :sizes="'200px'" :img="parameters.maintenance_logo" class="logo mb-4" />
				<div class="d-flex flex-column">
					<span class="material-icons mb-2"> engineering </span>

					<h1 class="fw-bold fs-4">{{ parameters.maintenance_text }}</h1>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Maintenance",
	computed: {
		parameters() {
			return this.$store.getters.getParameters;
		},
	},
};
</script>

<style lang="scss">
.page-maintenance {
	& > main {
		height: 100%;
	}
	img {
		width: 250px;
	}
}
</style>
