<template>
	<div class="news-item" :class="{short : short}">
		<div class="d-flex flex-column justify-content-start align-items-start news-item-header">
			<p class="news-item__title fs-4 mb-0">{{ content.title }}</p>
			<p class="date">{{ formatDate(content.published_at) }}</p>
		</div>

		<div class="news-border mb-3"></div>

		<div class="news-html-container">
			<div v-html="$mdToHtml(content.text)" class="html-content news-item-text"></div>
			<div class="news-html-opacity"></div>
		</div>
		
		<router-link v-if="short" class="btn button-more" :to="linkto">En savoir plus</router-link>
	</div>
</template>

<script>
export default {
	name: "newsItem",
	props: ["content", "short", "linkto"],
	methods : {
		formatDate(date) {
			let DATE = new Date(date);
			return DATE.getDate() + "/" + (DATE.getMonth() + 1) + "/" + DATE.getFullYear();
		},
	}
};
</script>

<style lang="scss" scoped>
.news-item{
			

			.news-item-header {
				//color: #e71f73;
				height: 75px;
				.news-item__title {
					font-family: var(--font-M);
					max-height: 50px;
					font-weight: 500;
					overflow: hidden;
				}
				p.date{
					margin-bottom: 0;
				}
				
				
			}
			.news-border{
				width: 30%;
				height: 1px;
				border-bottom: 5px solid #000;
			}
			.date {
				font-size: 0.7rem;
			}



			.news-item-text {
				
				margin-bottom: 1rem;
				p,
				ul,
				li,
				a {
					line-height: 1;
					font-size: 0.9rem;
				}

				img{
					max-width: 100%;
				}
			}


			&.short{
				.news-item-text{
					height: 78px;
					overflow: hidden;
				}
			}

			.news-html-container{
				position: relative;
			}
			.news-html-opacity{
				position: absolute;
				width: 100%;
				height: 80px;
				background: rgb(255,255,255);
				background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%);
				bottom: 0;
			}
		}
</style>
