<template>
	<footer class="row px-0 py-2 bg-primary">
		<div class="col-12">
			<div class="container">
				<div class="row py-2">
					<div class="col-12 col-md-6">
						<p class="footer-title fs-4">{{coords.name}}</p>
						<address v-html="$mdToHtml(coords.address)">
						
						</address>
						<div class="d-flex flex-row">
							<span class="phone material-icons"> phone </span><a class="link-reset ms-2" :href="'tel:+33'+coords.phone">{{coords.phone}}</a></div>
					</div>
					<div class="col-12 col-md-6">
						<p class="footer-title fs-4">Horaires : </p>
						<div class="row">
							<div class="col-12 col-md-6">
								<p class="mb-0" v-for="hour, ind in coords.hours" :key="'jour-'+ind">
									<span v-if="ind<4">{{hour.day}} : {{hour.start}}<span v-if="hour.end">-</span>{{hour.end}}<br></span>
								</p>
							</div>
							<div class="col-12 col-md-6">
									<p class="mb-0" v-for="hour, ind in coords.hours" :key="'jour-'+ind">
									<span v-if="ind>=4">{{hour.day}} : {{hour.start}}<span v-if="hour.end">-</span>{{hour.end}}<br></span>
								</p>
							</div>
						</div>
					
					

					</div>
					
				</div>
				<div class="row">
					<div class="col-12 text-center mt-4">
						<span class="credits">Site réalisé par <a href="https://www.davidanquetin.fr">David Anquetin</a> @ {{coords.name}} {{new Date().getFullYear()}}</span>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer",
	computed:{
		coords (){
			//console.log('COORDS are ', this.$store.getters.getCoords)
			return this.$store.getters.getCoords
		}
	},
};
</script>

<style lang="scss">
footer {
	&,
	a {
		color: #fff;
		font-size: 0.8rem;
	}
	.footer-title{
		font-weight: 500;
	}
	address{
		margin-bottom: 1rem;
	}
	address p{
		margin-bottom: 0;
	}

	.phone{
		font-size: 16px;
	}

	.credits{
		&, &>a{
			font-size: 11px;
		font-weight: 300;
		letter-spacing: 1.3px;
	}
		}
		
}
</style>
